import React, { FunctionComponent } from "react"

const Footer: FunctionComponent = () => {
  return (
    <footer>
      <div className="row">
        <div className="column">
          © CODALINE. All rights reserved. {new Date().getFullYear()}
        </div>
        <div className="column">
          <a href="/imprint">Impressum</a>
          <a href="/privacy">Datenschutz</a>
          <a target="_blank" href="/agb.pdf">
            AGB
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
