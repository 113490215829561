import React, { FunctionComponent } from "react"

import Footer from "../components/footer"
import SEO from "../components/seo"

import "./index.css"

const Layout: FunctionComponent<{
  children: JSX.Element | JSX.Element[]
  title: string
  description: string
  lang: "de"
  microdata?: string
}> = ({
  children,
  title = "codaline",
  description = "Remote Softwareentwicklung, Beratung, Prototyping & Code Audits",
  lang = "de",
  microdata,
}) => (
  <div className="page">
    <SEO
      lang={lang}
      title={title}
      description={description}
      microdata={microdata}
    />
    {children}
    <Footer />
  </div>
)

export default Layout
