import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO: FunctionComponent<{
  description?: string
  lang?: string
  meta?: any[]
  title?: string
  microdata?: string
}> = ({ description = ``, lang = `de`, meta = [], title = ``, microdata }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `favicon.png`,
        },
      ].concat(meta)}
    >
      {microdata && (
        <script type="application/ld+json">{`
          ${microdata}
        `}</script>
      )}
    </Helmet>
  )
}

export default SEO
